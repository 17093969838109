import { NavConfig } from './base';
import { MSalKoreaTestConfig } from '../../../config/msal-configs/korea/korea-test';
import { MSalKoreaProdConfig } from '../../../config/msal-configs/korea/korea-prod';
export class KoreaConfigV2 extends NavConfig {
    constructor(brand) {
        super();
        this._urls = {
            MyAccountProfileURL: '/api/_gbpe/v3/profiles',
            MyAccountRewardsURL: '',
            MyAccountVehicleURL: '/ownercenter/api/garagesummary',
            MyAccountMissingInfoURL: '',
            NotificationInboxURL: '/notif/delivery/inbox/v1/customer',
            KoreanProfileRefreshURL: '/bypass/korea/quantum/member/login-refresh.gm',
            KoreanCheckJsonURL: '/bypass/korea/quantum/member/ChkLoginJson.gm',
            logoutURL: '/member/logout.gm',
        };
        this._region = 'kr';
        this._language = 'ko';
        this._auth = Object.assign(Object.assign({}, MSalKoreaTestConfig), { authScopes: ['openid', 'profile'] });
        this._brand = brand;
        try {
            if (PROD_BUILD) {
                this._auth = {
                    tenant: MSalKoreaProdConfig.tenant,
                    authority: MSalKoreaProdConfig.authority,
                    policy: MSalKoreaProdConfig.policy,
                    clientID: MSalKoreaProdConfig.clientID,
                    authScopes: ['openid', 'profile'],
                    approvedAuthorities: MSalKoreaProdConfig.approvedAuthorities,
                };
            }
        }
        catch (e) {
            console.error('Error switching over to production configuration in Korea Headless Auth', e);
        }
    }
}
