import { LogLevel } from '@azure/msal-browser';
import { WaypointPathValues } from './waypoint-path-values';
import { appClientId, authBrand, authLocale, authWaypointTarget, channel, loginHint as hint, logoutUrl, requiredMIVal, requiredVal, selfScriptEl, sessionStorageKeyPrefixAttr, successTarget, useDataAttributes, environmentDataAttribute, countryDataAttribute, languageDataAttribute, rtlDataAttribute, } from './script-attributes';
const configurationMap = {
    Preproduction: {
        US: {
            en: {
                mapTenant: 'custlogin.pp.gm.com',
                mapAuthority: 'https://custlogin.pp.gm.com/gmb2ctest.onmicrosoft.com/B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapPolicy: 'B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapClientId: '2816e68f-d202-4b18-9cc1-809160ffd363',
                mapAuthScopes: ['openid', 'profile'],
                mapApprovedAuthorities: ['custlogin.pp.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '/api/_gbpe/v3/rewards',
                    MyAccountVehicleURL: '/ownercenter/api/garagesummary',
                    MyAccountMissingInfoURL: '/ownercenter/api/checkMissingInfo',
                    NotificationInboxURL: '/notif/delivery/inbox/v1/customer',
                    logoutURL: location.origin,
                },
            },
        },
        UK: {
            en: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/uk-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/uk-en',
                },
            },
        },
        CA: {
            en: {
                mapTenant: 'custlogin.pp.gm.com',
                mapAuthority: 'https://custlogin.pp.gm.com/gmb2ctest.onmicrosoft.com/B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapPolicy: 'B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapClientId: '0e518cb7-2ad3-4fc7-98ef-8c84d9f0f7a7',
                mapAuthScopes: ['openid', 'profile'],
                mapApprovedAuthorities: ['custlogin.pp.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '/en/api/_gbpe/v3/rewards',
                    MyAccountVehicleURL: '/en/ownercenter/api/garagesummary',
                    MyAccountMissingInfoURL: '/en/ownercenter/api/checkMissingInfo',
                    NotificationInboxURL: '/en/notif/delivery/inbox/v1/customer',
                    logoutURL: location.origin + '/en',
                },
            },
            fr: {
                mapTenant: 'custlogin.pp.gm.com',
                mapAuthority: 'https://custlogin.pp.gm.com/gmb2ctest.onmicrosoft.com/B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapPolicy: 'B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapClientId: '0e518cb7-2ad3-4fc7-98ef-8c84d9f0f7a7',
                mapAuthScopes: ['openid', 'profile'],
                mapApprovedAuthorities: ['custlogin.pp.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/fr/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '/fr/api/_gbpe/v3/rewards',
                    MyAccountVehicleURL: '/fr/ownercenter/api/garagesummary',
                    MyAccountMissingInfoURL: '/fr/ownercenter/api/checkMissingInfo',
                    NotificationInboxURL: '/fr/notif/delivery/inbox/v1/customer',
                    logoutURL: location.origin + '/fr',
                },
            },
        },
        CH: {
            en: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/ch-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/ch-en',
                },
            },
            fr: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/ch-fr/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/ch-fr',
                },
            },
            de: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/ch-de/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/ch-de',
                },
            },
        },
        DE: {
            en: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/de-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/de-en',
                },
            },
            de: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/de-de/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/de-de',
                },
            },
        },
        EU: {
            en: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/eu-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/eu-en',
                },
            },
        },
        FR: {
            en: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/fr-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/fr-en',
                },
            },
            fr: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/fr-fr/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/fr-fr',
                },
            },
        },
        SE: {
            en: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/se-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/se-en',
                },
            },
            sv: {
                mapTenant: 'gmb2ctest.b2clogin.com',
                mapAuthority: 'https://gmb2ctest.b2clogin.com/gmb2ctest.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31',
                mapAuthScopes: ['openid', 'profile', 'cb0c5ea3-f76f-4c3b-aaa5-3467caa50b31'],
                mapApprovedAuthorities: ['gmb2ctest.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/se-sv/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/se-sv',
                },
            },
        },
        KR: {
            ko: {
                mapTenant: 'custlogin.pp.gm.com',
                mapAuthority: 'https://custlogin.pp.gm.com/gmb2ctest.onmicrosoft.com/b2c_1a_sk_seamlessmigration_signuporsignin',
                mapPolicy: 'b2c_1a_sk_seamlessmigration_signuporsignin',
                mapClientId: 'c28ada13-0014-4ff3-9614-308c9a98be46',
                mapAuthScopes: ['openid', 'profile'],
                mapApprovedAuthorities: ['custlogin.pp.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '/ownercenter/api/garagesummary',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '/notif/delivery/inbox/v1/customer',
                    KoreanProfileRefreshURL: '/bypass/korea/quantum/member/login-refresh.gm',
                    KoreanCheckJsonURL: '/bypass/korea/quantum/member/ChkLoginJson.gm',
                    logoutURL: location.origin,
                },
            },
        },
        JP: {
            ja: {
                mapTenant: 'custlogin.pp.gm.com',
                mapAuthority: 'https://custlogin.pp.gm.com/gmb2ctest.onmicrosoft.com/B2C_1A_SEAMLESSMIGRATION_SIGNUPORSIGNIN/v2.0/.well-known/openid-configuration',
                mapPolicy: 'B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapClientId: '5d2668e0-65ae-475c-ab3e-518aba72d487',
                mapAuthScopes: ['openid', 'profile'],
                mapApprovedAuthorities: ['custlogin.pp.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '/api/_gbpe/v3/rewards',
                    MyAccountVehicleURL: '/ownercenter/api/garagesummary',
                    MyAccountMissingInfoURL: '/ownercenter/api/checkMissingInfo',
                    NotificationInboxURL: '/notif/delivery/inbox/v1/customer',
                    logoutURL: location.origin,
                },
            },
        },
        NZ: {
            en: {
                mapTenant: 'custlogin.pp.gm.com',
                mapAuthority: 'https://custlogin.pp.gm.com/gmb2ctest.onmicrosoft.com/B2C_1A_ANZ_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_ANZ_SIGNUPORSIGNIN',
                mapClientId: '5d2668e0-65ae-475c-ab3e-518aba72d487',
                mapAuthScopes: ['openid', 'profile', '5d2668e0-65ae-475c-ab3e-518aba72d487'],
                mapApprovedAuthorities: ['custlogin.pp.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/nz-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/nz-en',
                },
            },
        },
        AU: {
            en: {
                mapTenant: 'custlogin.pp.gm.com',
                mapAuthority: 'https://custlogin.pp.gm.com/gmb2ctest.onmicrosoft.com/B2C_1A_ANZ_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_ANZ_SIGNUPORSIGNIN',
                mapClientId: '5d2668e0-65ae-475c-ab3e-518aba72d487',
                mapAuthScopes: ['openid', 'profile', '5d2668e0-65ae-475c-ab3e-518aba72d487'],
                mapApprovedAuthorities: ['custlogin.pp.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/au-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/au-en',
                },
            },
        },
    },
    Production: {
        US: {
            en: {
                mapTenant: 'custlogin.gm.com',
                mapAuthority: 'https://custlogin.gm.com/gmb2cprod.onmicrosoft.com/B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapPolicy: 'B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapClientId: '43b9895e-a54a-412e-b11d-eaf11dac570d',
                mapAuthScopes: ['openid', 'profile'],
                mapApprovedAuthorities: ['custlogin.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '/api/_gbpe/v3/rewards',
                    MyAccountVehicleURL: '/ownercenter/api/garagesummary',
                    MyAccountMissingInfoURL: '/ownercenter/api/checkMissingInfo',
                    NotificationInboxURL: '/notif/delivery/inbox/v1/customer',
                    logoutURL: location.origin,
                },
            },
        },
        UK: {
            en: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/uk-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/uk-en',
                },
            },
        },
        CA: {
            en: {
                mapTenant: 'custlogin.gm.com',
                mapAuthority: 'https://custlogin.gm.com/gmb2cprod.onmicrosoft.com/B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapPolicy: 'B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapClientId: '0e518cb7-2ad3-4fc7-98ef-8c84d9f0f7a7',
                mapAuthScopes: ['openid', 'profile'],
                mapApprovedAuthorities: ['custlogin.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '/en/api/_gbpe/v3/rewards',
                    MyAccountVehicleURL: '/en/ownercenter/api/garagesummary',
                    MyAccountMissingInfoURL: '/en/ownercenter/api/checkMissingInfo',
                    NotificationInboxURL: '/en/notif/delivery/inbox/v1/customer',
                    logoutURL: location.origin + '/en',
                },
            },
            fr: {
                mapTenant: 'custlogin.gm.com',
                mapAuthority: 'https://custlogin.gm.com/gmb2cprod.onmicrosoft.com/B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapPolicy: 'B2C_1A_SeamlessMigration_SignUpOrSignIn',
                mapClientId: '0e518cb7-2ad3-4fc7-98ef-8c84d9f0f7a7',
                mapAuthScopes: ['openid', 'profile'],
                mapApprovedAuthorities: ['custlogin.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/fr/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '/fr/api/_gbpe/v3/rewards',
                    MyAccountVehicleURL: '/fr/ownercenter/api/garagesummary',
                    MyAccountMissingInfoURL: '/fr/ownercenter/api/checkMissingInfo',
                    NotificationInboxURL: '/fr/notif/delivery/inbox/v1/customer',
                    logoutURL: location.origin + '/fr',
                },
            },
        },
        CH: {
            en: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/ch-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/ch-en',
                },
            },
            fr: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/ch-fr/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/ch-fr',
                },
            },
            de: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/ch-de/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/ch-de',
                },
            },
        },
        DE: {
            en: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/de-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/de-en',
                },
            },
            de: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/de-de/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/de-de',
                },
            },
        },
        EU: {
            en: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/eu-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/eu-en',
                },
            },
        },
        FR: {
            en: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/fr-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/fr-en',
                },
            },
            fr: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/fr-fr/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/fr-fr',
                },
            },
        },
        SE: {
            en: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/se-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/se-en',
                },
            },
            sv: {
                mapTenant: 'gmb2ceuprod.b2clogin.com',
                mapAuthority: 'https://gmb2ceuprod.b2clogin.com/gmb2ceuprod.onmicrosoft.com/B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_EU_SEAMLESSMIGRATION_SIGNUPORSIGNIN',
                mapClientId: '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb',
                mapAuthScopes: ['openid', 'profile', '5420a4ae-6e73-4e1e-b29d-efadcb7aa8cb'],
                mapApprovedAuthorities: ['gmb2ceuprod.b2clogin.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/se-sv/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/se-sv',
                },
            },
        },
        KR: {
            ko: {
                mapTenant: 'custlogin.gm.com',
                mapAuthority: 'https://custlogin.gm.com/gmb2cprod.onmicrosoft.com/b2c_1a_sk_seamlessmigration_signuporsignin',
                mapPolicy: 'b2c_1a_sk_seamlessmigration_signuporsignin',
                mapClientId: 'fda9cb4b-a06d-48fd-86a7-cecaf98251e3',
                mapAuthScopes: ['openid', 'profile'],
                mapApprovedAuthorities: ['custlogin.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '/ownercenter/api/garagesummary',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '/notif/delivery/inbox/v1/customer',
                    KoreanProfileRefreshURL: '/bypass/korea/quantum/member/login-refresh.gm',
                    KoreanCheckJsonURL: '/bypass/korea/quantum/member/ChkLoginJson.gm',
                    logoutURL: location.origin,
                },
            },
        },
        JP: {
            ja: {
                mapTenant: 'custlogin.gm.com',
                mapAuthority: 'https://custlogin.gm.com/gmb2cprod.onmicrosoft.com/b2c_1a_sk_seamlessmigration_signuporsignin',
                mapPolicy: 'b2c_1a_sk_seamlessmigration_signuporsignin',
                mapClientId: '5a633e95-1b6a-4de0-be2b-b1590e82af53',
                mapAuthScopes: ['openid', 'profile'],
                mapApprovedAuthorities: ['custlogin.gm.com'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '/ownercenter/api/garagesummary',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '/notif/delivery/inbox/v1/customer',
                    logoutURL: location.origin,
                },
            },
        },
        NZ: {
            en: {
                mapTenant: 'PLACEHOLDER',
                mapAuthority: 'https://PLACEHOLDER/gmb2cprod.onmicrosoft.com/B2C_1A_ANZ_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_ANZ_SIGNUPORSIGNIN',
                mapClientId: '5a633e95-1b6a-4de0-be2b-b1590e82af53',
                mapAuthScopes: ['openid', 'profile', '5a633e95-1b6a-4de0-be2b-b1590e82af53'],
                mapApprovedAuthorities: ['PLACEHOLDER'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/nz-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/nz-en',
                },
            },
        },
        AU: {
            en: {
                mapTenant: 'PLACEHOLDER',
                mapAuthority: 'https://PLACEHOLDER/gmb2cprod.onmicrosoft.com/B2C_1A_ANZ_SIGNUPORSIGNIN',
                mapPolicy: 'B2C_1A_ANZ_SIGNUPORSIGNIN',
                mapClientId: '5a633e95-1b6a-4de0-be2b-b1590e82af53',
                mapAuthScopes: ['openid', 'profile', '5a633e95-1b6a-4de0-be2b-b1590e82af53'],
                mapApprovedAuthorities: ['PLACEHOLDER'],
                mapRedirectURI: '/',
                mapPostLogoutRedirectURI: '/',
                urls: {
                    MyAccountProfileURL: '/au-en/api/_gbpe/v3/profiles',
                    MyAccountRewardsURL: '',
                    MyAccountVehicleURL: '',
                    MyAccountMissingInfoURL: '',
                    NotificationInboxURL: '',
                    logoutURL: location.origin + '/au-en',
                },
            },
        },
    },
};
export function getKoreanAuthConfig(config) {
    var _a;
    const loginHint = hint ? hint.trim() : '';
    const sessionStorageKeyPrefix = sessionStorageKeyPrefixAttr;
    const authRequired = requiredVal !== null && requiredVal !== 'false';
    let locale;
    let scopes;
    let region;
    if (useDataAttributes === 'true') {
        locale = `${languageDataAttribute}-${countryDataAttribute.toUpperCase()}`;
        scopes = configurationMap[environmentDataAttribute][countryDataAttribute][languageDataAttribute].mapAuthScopes;
        region = countryDataAttribute.toUpperCase();
        config._urls = configurationMap[environmentDataAttribute][countryDataAttribute][languageDataAttribute].urls;
        if (rtlDataAttribute === 'true') {
            document.dir = 'rtl';
        }
    }
    else {
        locale = authLocale ? authLocale : `${config.language}-${config.region.toUpperCase()}`;
        scopes = config.auth.authScopes;
        region = config.region.toUpperCase();
    }
    const extraQueryParameters = getExtraQueryParams(config, locale);
    const regularState = document.URL + '|' + locale;
    const isMyBA = ((_a = selfScriptEl === null || selfScriptEl === void 0 ? void 0 : selfScriptEl.src) === null || _a === void 0 ? void 0 : _a.includes('myba')) === true;
    const baseRequest = {
        scopes: scopes,
        redirectUri: isMyBA === true ? getWaypointTarget(WaypointPathValues.myBAWaypointSuffix) : getWaypointTarget(),
        state: regularState,
    };
    const redirectRequest = Object.assign({ extraQueryParameters }, baseRequest);
    const signUpRequest = Object.assign({ onRedirectNavigate: () => false, extraQueryParameters }, baseRequest);
    return {
        authRequired,
        locale,
        loginHint,
        msalConfig: getMsalConfig(config),
        redirectRequest,
        region: region.toUpperCase(),
        sessionStorageKeyPrefix,
        signUpRequest,
    };
}
function getExtraQueryParams(config, locale) {
    const channelParam = channel;
    const rcsitURL = window.location.href.includes('rcsit');
    const channelVal = channelParam ? channelParam : rcsitURL ? config.channel + 'rcsit' : config.channel;
    return {
        brand: authBrand ? authBrand : config.brand,
        channel: channelVal,
        requiredMissingInfo: requiredMIVal,
        ui_locales: locale,
    };
}
function getMsalConfig(config) {
    let postLogoutURL;
    let applicationClientId;
    let authority;
    let approvedAuthorities;
    if (useDataAttributes === 'true') {
        postLogoutURL =
            configurationMap[environmentDataAttribute][countryDataAttribute][languageDataAttribute]
                .mapPostLogoutRedirectURI;
        applicationClientId =
            configurationMap[environmentDataAttribute][countryDataAttribute][languageDataAttribute].mapClientId;
        authority = configurationMap[environmentDataAttribute][countryDataAttribute][languageDataAttribute].mapAuthority;
        approvedAuthorities =
            configurationMap[environmentDataAttribute][countryDataAttribute][languageDataAttribute]
                .mapApprovedAuthorities;
    }
    else {
        postLogoutURL = logoutUrl ? logoutUrl : config.urls.logoutURL;
        applicationClientId = appClientId || config.auth.clientID;
        authority = config.auth.authority;
        approvedAuthorities = config.auth.approvedAuthorities;
    }
    const storageLocation = 'sessionStorage';
    const msalConfig = {
        auth: {
            clientId: applicationClientId,
            authority: authority,
            knownAuthorities: approvedAuthorities,
            redirectUri: successTarget,
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: postLogoutURL,
        },
        cache: {
            cacheLocation: storageLocation,
            storeAuthStateInCookie: false,
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                },
                piiLoggingEnabled: false,
            },
        },
    };
    return msalConfig;
}
function getWaypointTarget(defaultWaypointTarget = WaypointPathValues.waypointSuffix) {
    const scriptFilePath = new URL(selfScriptEl.src).pathname;
    const pathSubstringLength = scriptFilePath.lastIndexOf('/');
    const fallbackWaypointHTMLPath = scriptFilePath.substring(0, pathSubstringLength) + defaultWaypointTarget;
    const fallbackWaypointTarget = window.location.origin + fallbackWaypointHTMLPath;
    return authWaypointTarget ? authWaypointTarget : fallbackWaypointTarget;
}
