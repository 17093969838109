import { getBaseHeadlessAuth, PortableExperiences } from '../../../../utils/portable-experiences';
import { KoreaUserInformationV2 } from '../../user-info/korea-v2/korea-user-info-v2';
import { Overlay } from '../../../overlay';
import { checkMissingInfo } from '../../user-info/missing-info-api';
import { getKoreanAuthConfig } from '../../../../config/korea-auth-config';
import { initializeGbpe } from '../../../../utils/initialize-gbpe';
import { AuthContentService } from '../../../../utils/auth-content-service';
import { EntitlementService } from '../../../../utils/auth/entitlement-service';
export function initKoreaV2Gbpe(config, missingInfo = true) {
    const authConfig = getKoreanAuthConfig(config);
    const userInfo = new KoreaUserInformationV2(config.urls);
    const baseGbpeObject = PortableExperiences(authConfig, userInfo, getBaseHeadlessAuth(authConfig, config.urls, authConfig.sessionStorageKeyPrefix), new Overlay(document.body), config.urls, new EntitlementService(), new AuthContentService());
    initializeGbpe(authConfig, userInfo, config, baseGbpeObject);
    if (missingInfo === true) {
        checkMissingInfo().then();
    }
}
