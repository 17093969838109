var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BrowserAuthError, PublicClientApplication, } from '@azure/msal-browser';
import { getVerificationStatusFromToken, redirectToVerificationPage, getRegionRedirect } from './verify-token';
import { isAbsoluteUrl } from '@gbds/core/src/ts/utils';
import { AuthEvents, AuthState, AuthStorageKeys, clearUserInfo, verificationConstants, } from '../../config/auth-enumerations';
import { InactivityEvents, InactivityStorageKeys, InactivityTracker } from '../inactivity-tracker';
import { CREATE_ACCOUNT_URL, API_LOGOUT_CLEAR_COOKIE_PATH } from '../../config/paths';
import { reportErrorToDynatrace } from '../dynatrace-error-logging';
export class StandardClientApplication {
    constructor(config, _urlConfig, required = false) {
        this._urlConfig = _urlConfig;
        this._createAccountURL = window.location.origin + CREATE_ACCOUNT_URL;
        this._clientApp = new PublicClientApplication(config.msalConfig);
        this._clientId = config.msalConfig.auth.clientId;
        this._redirectRequest = config.redirectRequest;
        this._signupRequest = config.signUpRequest;
        this._locale = config.locale;
        this._region = config.region;
        this._loginHint = config.loginHint;
        this._exceptionThrown = false;
        this._state = sessionStorage.getItem(AuthStorageKeys.state)
            ? sessionStorage.getItem(AuthStorageKeys.state)
            : config.redirectRequest.state.split('|')[0];
        this._handleRedirectPromise(required);
    }
    _handleRedirectPromise(required) {
        this._clientApp
            .handleRedirectPromise()
            .then(res => {
            if (res) {
                this._handleAuthResult(res, required);
            }
            else {
                this._selectAccount(required);
            }
        })
            .catch(this._handleAuthError.bind(this))
            .finally(() => {
            if (this._exceptionThrown === true) {
                location.replace(this._urlConfig.logoutURL);
            }
        });
    }
    _retryAPICall(callPath, tries, waitTime, error, callParams, failedMessage) {
        return __awaiter(this, void 0, void 0, function* () {
            let callLoopCount = 0;
            let responseStatus;
            while (callLoopCount < tries) {
                try {
                    const fetch_params = callParams !== null && callParams !== void 0 ? callParams : {};
                    fetch(callPath, fetch_params)
                        .then(response => {
                        console.log(`Response Status ${response.status}`);
                        responseStatus = response.status;
                    })
                        .catch(err => {
                        throw err;
                    });
                    callLoopCount++;
                }
                catch (_a) {
                    throw error;
                }
                finally {
                    if (responseStatus === 200) {
                        return true;
                    }
                    else {
                        if (failedMessage) {
                            console.log(failedMessage + ' (' + responseStatus + ')');
                        }
                        if (callLoopCount === tries) {
                            break;
                        }
                        yield new Promise(resolve => setTimeout(resolve, waitTime));
                    }
                }
            }
            return false;
        });
    }
    _setAuthenticated(expiration) {
        this._accessExpiration = expiration;
        this._authState = AuthState.authenticated;
        this._dispatchAuthChecked();
        const customTimeout = sessionStorage.getItem(InactivityStorageKeys.customTimeOut);
        const timeLimit = customTimeout && !isNaN(parseInt(customTimeout)) && parseInt(customTimeout) <= 15 * 60 * 1000
            ? parseInt(customTimeout)
            : 15 * 60 * 1000;
        this._timer = new InactivityTracker(timeLimit);
        this._boundInactiveUserHandler = this._handleInactiveUser.bind(this);
        window.addEventListener(InactivityEvents.inactiveUser, this._boundInactiveUserHandler);
        this._boundStorageHandler = this._handleStorageEvent.bind(this);
        window.addEventListener('storage', this._boundStorageHandler);
    }
    _handleAuthResult(response, required) {
        this._accessToken = response.accessToken !== '' ? response.accessToken : undefined;
        this._idToken = response.idToken;
        this._selectAccount(required);
        const emailVerifiedProperty = getVerificationStatusFromToken(this._idToken);
        const emailVerified = emailVerifiedProperty && emailVerifiedProperty.toLowerCase() === verificationConstants.verified;
        if (emailVerified || this._region.toUpperCase() === 'CH' || this._region.toUpperCase() === 'SE') {
            const localeIndex = response.state.indexOf('|');
            if (localeIndex > -1) {
                this._redirectToState(response.state.substr(0, localeIndex));
            }
            else {
                this._redirectToState(response.state);
            }
        }
    }
    _handleAuthError(error) {
        this._resetRedirectURIs();
        this._exceptionThrown = true;
        console.error('Error occurred in standard application', error);
        reportErrorToDynatrace(error);
        this._dispatchAuthChecked();
    }
    _resetRedirectURIs(RedirectURI) {
        this._redirectRequest.redirectUri = RedirectURI || document.documentURI;
    }
    _selectAccount(required) {
        const currentAccounts = this._clientApp.getAllAccounts();
        if (currentAccounts.length === 0) {
            this._handleNoAccounts(required);
            return;
        }
        else if (currentAccounts.length > 1) {
            this._handleMultipleAccounts(required);
        }
        else if (currentAccounts.length === 1) {
            this._handleSingleAccount(currentAccounts[0], required);
        }
    }
    _handleNoAccounts(required) {
        const redirect_after_logout = sessionStorage.getItem(InactivityStorageKeys.userSignedOut);
        if (redirect_after_logout && redirect_after_logout === 'true') {
            sessionStorage.removeItem(InactivityStorageKeys.userSignedOut);
            console.log('User has been signed out due to inactivity');
        }
        this._checkAuthLocalStorage();
        const forcedLoginIndicator = sessionStorage.getItem(AuthStorageKeys.forceLoginIndicator);
        const promptLogin = sessionStorage.getItem(AuthStorageKeys.promptLogin) === 'true';
        if (required || (forcedLoginIndicator && forcedLoginIndicator === 'true')) {
            sessionStorage.removeItem(AuthStorageKeys.forceLoginIndicator);
            if (required) {
                this.loginRedirect(window.location.href, undefined, promptLogin);
            }
            else {
                this.loginRedirect(window.location.origin, undefined, promptLogin);
            }
        }
    }
    _handleMultipleAccounts(required) {
        console.warn('Auth: Multiple accounts detected.');
        const latestAccount = this._clientApp.getAllAccounts()[0];
        this._handleSingleAccount(latestAccount, required);
    }
    _handleSingleAccount(currentAccount, required) {
        return __awaiter(this, void 0, void 0, function* () {
            this._accountInfo = currentAccount;
            this._clientApp.setActiveAccount(this._accountInfo);
            const expirationSeconds = Number(this._accountInfo.idTokenClaims.exp);
            const expirationDate = new Date(expirationSeconds * 1000);
            this._setIdTokenFromStorage();
            this._setAccessTokenFromStorage();
            const tokenExpired = this._tokenExpired(expirationDate);
            if (tokenExpired) {
                this._attemptAcquireTokenSilent(required);
            }
            else {
                this._setAuthenticated(expirationDate);
                this._setExpirationTracker(expirationDate, required);
                this._handleUnverifiedUser();
            }
        });
    }
    _attemptAcquireTokenSilent(required) {
        this._clientApp
            .acquireTokenSilent(Object.assign(Object.assign({}, this._redirectRequest), { account: this._accountInfo }))
            .then(this._handleAuthResult.bind(this))
            .catch(() => {
            if (required) {
                this.loginRedirect(window.location.href);
            }
            else {
                this.logout();
            }
        });
    }
    _checkAuthLocalStorage() {
        this._setUnauthenticated();
    }
    _setUnauthenticated() {
        this._authState = AuthState.unAuthenticated;
        this._dispatchAuthChecked();
    }
    _dispatchAuthChecked() {
        window.dispatchEvent(new CustomEvent(AuthEvents.loaded));
    }
    _tokenExpired(timeToCheck) {
        const now = Date.now();
        const difference = now - timeToCheck.getTime();
        return difference > 0;
    }
    _handleRedirectError(error) {
        if (error instanceof BrowserAuthError && error.errorCode === 'interaction_in_progress') {
            sessionStorage.removeItem(`msal.${this._clientId}.request.params`);
            sessionStorage.removeItem(`msal.${this._clientId}.interaction.status`);
        }
        else {
            console.error(error);
        }
    }
    _setIdTokenFromStorage() {
        var _a, _b;
        const idTokenStorageKey = this._accountInfo.homeAccountId + '-' + this._accountInfo.environment + '-idtoken-' + this._clientId + '--';
        let storageIdToken;
        if (this._locale === 'ko-KR') {
            storageIdToken = (_a = JSON.parse(sessionStorage.getItem(idTokenStorageKey))) === null || _a === void 0 ? void 0 : _a.secret;
        }
        else {
            storageIdToken = (_b = JSON.parse(localStorage.getItem(idTokenStorageKey))) === null || _b === void 0 ? void 0 : _b.secret;
        }
        if (this._idToken == null && storageIdToken) {
            this._idToken = storageIdToken;
        }
    }
    _setAccessTokenFromStorage() {
        const accessTokenStorageKey = this._accountInfo.homeAccountId +
            '-' +
            this._accountInfo.environment +
            '-accesstoken-' +
            this._clientId +
            '--' +
            this._clientId;
        let storageAccessToken;
        if (this._locale === 'ko-KR') {
            storageAccessToken = sessionStorage.getItem(accessTokenStorageKey)
                ? JSON.parse(sessionStorage.getItem(accessTokenStorageKey)).secret
                : null;
        }
        else {
            storageAccessToken = localStorage.getItem(accessTokenStorageKey)
                ? JSON.parse(localStorage.getItem(accessTokenStorageKey)).secret
                : null;
        }
        if (this._accessToken == null && storageAccessToken) {
            this._accessToken = storageAccessToken;
        }
    }
    _redirectToState(stateParam) {
        let finalURL;
        if (stateParam && stateParam != '') {
            this._state = stateParam;
        }
        if (this._state && isAbsoluteUrl(this._state)) {
            finalURL = new URL(this._state);
        }
        else {
            finalURL = new URL(this._state, window.location.origin);
        }
        if (finalURL && finalURL != document.URL && finalURL.origin === window.location.origin) {
            window.location.replace(finalURL.href);
        }
        else if (!finalURL) {
            console.error('State Parameter from request does not exist');
        }
        else if (finalURL.origin !== window.location.origin) {
            console.error('The Final landing page is not on the same domain as the current page');
        }
        else {
            console.log('User has reached final landing page');
        }
    }
    _setExpirationTracker(expirationDate, required) {
        const now = Date.now();
        const timeTilExpiration = expirationDate.getTime() - now;
        const timeToInvoke = timeTilExpiration > 0 ? timeTilExpiration : -1;
        window.setTimeout(() => {
            this._attemptAcquireTokenSilent(required);
        }, timeToInvoke);
    }
    _handleUnverifiedUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const emailVerifiedProperty = getVerificationStatusFromToken(this._idToken);
            const emailVerified = emailVerifiedProperty && emailVerifiedProperty.toLowerCase() === verificationConstants.verified;
            if (!emailVerified && 'SE' !== this._region.toUpperCase() && 'CH' !== this._region.toUpperCase()) {
                const redirectURL = yield getRegionRedirect(this._idToken, this._locale, this._region, this._urlConfig.MyAccountProfileURL);
                redirectToVerificationPage(redirectURL);
            }
        });
    }
    _handleInactiveUser() {
        sessionStorage.setItem(InactivityStorageKeys.userSignedOut, 'true');
        this.logout();
    }
    _handleStorageEvent(event) {
        if (event.key === AuthStorageKeys.userLogout && event.newValue !== null) {
            this.logout();
        }
        else {
            if (event.key === AuthStorageKeys.userActive && event.newValue !== null) {
                this._timer.resetTimer();
            }
        }
    }
    _emitLocalStorageLogout() {
        localStorage.setItem(AuthStorageKeys.userLogout, 'true');
        localStorage.removeItem(AuthStorageKeys.userLogout);
    }
    loginRedirect(url, extraParameters, forceLogin) {
        var _a, _b, _c;
        if (this._authenticated)
            return;
        let stateURL;
        if (url && url != '') {
            if (isAbsoluteUrl(url)) {
                stateURL = new URL(url);
            }
            else {
                stateURL = new URL(url, window.location.origin);
            }
        }
        const stateParam = (stateURL === null || stateURL === void 0 ? void 0 : stateURL.origin) === window.location.origin
            ? stateURL.href
            : (_c = (_b = (_a = this._redirectRequest) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.split('|')[0]) !== null && _c !== void 0 ? _c : this._state;
        if ((stateURL === null || stateURL === void 0 ? void 0 : stateURL.origin) === window.location.origin) {
            this._redirectRequest.state = stateURL.href + '|' + this._locale;
        }
        else {
            if (stateURL) {
                console.error('URL included in login redirect function not on same domain');
            }
        }
        if (stateParam) {
            sessionStorage.setItem(AuthStorageKeys.state, stateParam);
        }
        if (this._loginHint && this._loginHint !== '') {
            this._redirectRequest.extraQueryParameters.login_hint = this._loginHint;
            this._redirectRequest.extraQueryParameters.isNoo = 'true';
        }
        if (extraParameters) {
            const currentQueryParams = this._redirectRequest.extraQueryParameters;
            this._redirectRequest.extraQueryParameters = Object.assign(Object.assign({}, currentQueryParams), extraParameters);
        }
        if (forceLogin !== undefined && forceLogin === true) {
            sessionStorage.removeItem(AuthStorageKeys.promptLogin);
            this._redirectRequest.prompt = 'login';
        }
        this._clientApp.loginRedirect(this._redirectRequest).catch(error => {
            this._handleRedirectError(error);
        });
    }
    logout(url) {
        sessionStorage.removeItem(AuthStorageKeys.serverOffset);
        sessionStorage.removeItem(InactivityStorageKeys.customTimeOut);
        clearUserInfo(this._urlConfig);
        this.clearCookie();
        window.removeEventListener(InactivityEvents.inactiveUser, this._boundInactiveUserHandler);
        window.removeEventListener('storage', this._boundStorageHandler);
        this._emitLocalStorageLogout();
        const isSameDomain = url ? !isAbsoluteUrl(url) || new URL(url).origin === window.location.origin : false;
        if (url && isSameDomain) {
            let urlObj;
            if (isAbsoluteUrl(url)) {
                urlObj = new URL(url);
            }
            else {
                urlObj = new URL(url, window.location.origin);
            }
            const logoutRequest = {
                postLogoutRedirectUri: urlObj.href,
            };
            this._clientApp.logout(logoutRequest).catch(console.error);
        }
        else {
            this._clientApp.logout().catch(console.error);
        }
    }
    clearCookie() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(API_LOGOUT_CLEAR_COOKIE_PATH);
                const responseStatus = yield response.status;
                if (responseStatus != 200) {
                    console.log('ERROR: Clear Cookie API Response Status ' + responseStatus);
                }
            }
            catch (_a) {
                throw new Error(`Clear Cookie API has not been called`);
            }
        });
    }
    registerRedirect(accountRegisterURL, stateUrl) {
        const accountRegisterRequest = Object.assign({}, this._signupRequest);
        accountRegisterRequest.onRedirectNavigate = this._registerNavigateHandler.bind(this);
        if (accountRegisterURL) {
            if (isAbsoluteUrl(accountRegisterURL)) {
                const fullCreateAccountURL = new URL(accountRegisterURL);
                this._createAccountURL = fullCreateAccountURL.origin + fullCreateAccountURL.pathname;
            }
            else {
                const fullCreateAccountURL = new URL(accountRegisterURL, window.location.origin);
                this._createAccountURL = fullCreateAccountURL.origin + fullCreateAccountURL.pathname;
            }
        }
        if (stateUrl) {
            if (isAbsoluteUrl(stateUrl)) {
                accountRegisterRequest.state = stateUrl;
            }
            else {
                accountRegisterRequest.state = new URL(stateUrl, window.location.origin).href;
            }
        }
        this._clientApp
            .loginRedirect(accountRegisterRequest)
            .then(this._registerNavigateCallback.bind(this))
            .catch(error => {
            this._handleRedirectError(error);
        });
    }
    _registerNavigateHandler(url) {
        const azureQueryParams = new URL(url).search;
        const fullAccountURLPath = new URL(this._createAccountURL + azureQueryParams);
        this._myAccountURL = fullAccountURLPath.href;
        return false;
    }
    _registerNavigateCallback() {
        window.location.href = this._myAccountURL;
    }
    get idToken() {
        return this._idToken;
    }
    get accessToken() {
        return this._accessToken;
    }
    get missingInfoRequired() {
        return this._redirectRequest.extraQueryParameters.requiredMissingInfo;
    }
    get authState() {
        return this._authState;
    }
    setInactiveTimeOut(timeout) {
        const inactiveTime = timeout.toString();
        sessionStorage.setItem(InactivityStorageKeys.customTimeOut, inactiveTime);
        window.location.reload();
    }
}
